.org-content-view {
    .section-frame {
        overflow: hidden;
        margin-bottom: 48px;
        transition: 0.3s ease-in-out;

        .header-frame {
            display: flex;
            padding: 5px 10px;
            background: #F2F2F2;
            justify-content: space-between;

            .header {
                font-size: 24px;
                font-weight: 600;
            }

            .flex-frame {
                display: flex;
                align-items: center;
                
                .select-all-button, .expand-button {
                    margin: 0px;
                    padding: 0px;
                    border: none;
                    font-size: 12px;
                    background: none;
                }

                .select-all-button {
                    margin-right: 30px;
                }
            }
        }

        .section-list-view {
            padding: 10px;
            column-count: 3;
            list-style: none;
            margin: 15px 0px 0px 0px;
            -webkit-column-count: 3;

            .section-cell {
                display: flex;
                cursor: pointer;
                margin-right: 1%;
                margin-bottom: 15px;
                align-items: center;
                page-break-inside: avoid;

                .checkbox {
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: 16px;
                }

                .section-cell-title {
                    font-size: 12px;
                    margin-left: 10px;
                }

                .section-cell-title.red {
                    font-size: 9px;
                    margin-left: 3px;
                }
            }
        }
    }
}