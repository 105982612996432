.job-post-view {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  .field-title {
    font-weight: bold;
  }

  .centered-frame {
    margin: 60px auto;
  }

  .main-content-frame {
    border: 0.5px solid black;
    padding: 40px;
    margin-bottom: 30px;

    .title {
      font-size: 26px;
      font-weight: bold;
      padding-bottom: 15px;
      color: #FF5A5F;
    }
  }

  .job-details-view {
    display: flex;

    .inner-frame {
      border: 0.5px solid black;
      padding: 40px;
    }

    .left-section {
      flex: 1;

      .job-detail-grid {
        display: grid;
        position: relative;
        grid-gap: 20px;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .right-section {
    width: 30%;
    margin-left: 30px;
    height: fit-content;
  }

  .border {
    border: 0.5px solid black;
  }

  .block-small {
    padding: 20px;
  }

  .description-frame {
    margin: 20px 0 0 0;
  }

}