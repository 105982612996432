.description {
  grid-area: description;
}

.card0 {
  grid-area: card0;
}

.card1 {
  grid-area: card1;
}

.pricePage {
  display: grid;
  grid-template-areas:
    "description card0"
    "description card1";
  gap: 50px;
  width: 100%;
  height: 550px;

  .description {
    border-radius: 15px;
    padding: 20px;
    padding-top: 0px;
    text-align: left;
    background-color: white;

    h2 {
      position: sticky;
      color: #008489;
      padding-bottom: 0px;
      border-bottom: 2px solid #acacac;
    }

    .desc-content {
      max-height: 400px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .card {
    background-color: white;
    border-radius: 15px;
    padding: 15px 0px;

    .bestValue {
      h5 {
        padding: 5px 20px;
        margin: 0;
        text-shadow: 1px 1px 3px rgba($color: #000000, $alpha: 0.7);
      }

      width: 150px;
      background-color: pink;
      position: relative;
      color: white;

      &::after {
        content: "";
        width: 27px;
        height: 27px;
        background-color: white;
        position: absolute;
        top: 0;
        transform: rotate(45deg);
        right: -10px;
      }
    }

    .cardContent {
      padding: 0px 20px;

      h2 {
        margin-bottom: 5px;
      }

      .price {
        del {
          color: #acacac;
        }

        font-size: 32px;
        font-weight: 700;
        color: black;

        span {
          font-size: 20px;
          color: #008489;
        }
      }

      .Col2 {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        color: #008489;
        font-weight: 500;

        .blktxt {
          color: black;
        }
      }
    }
  }

  .card0 {
    background-color: white;
    border-radius: 15px;
  }

  .card1 {
    background-color: white;
    border-radius: 15px;
  }

  button {
    background-color: #ff5a5f;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
  }

  .NoValue {
    display: none;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .pricePage {
    grid-template-areas:
      "card0 card1"
      "description description";
    gap: 20px;

    .description {
      .desc-content {
        max-height: 100%;
      }
    }

    .Col2 {
      font-size: 12px;

      button {
        font-size: 14px;
        padding: 10px;
      }

      .cardContent:nth-child(1) {
        h2 {
          padding-top: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .pricePage {
    padding: 20px;
    grid-template-areas:
      "card0"
      "card1"
      "description";

    .description {
      .desc-content {
        max-height: 430px;
      }
    }

    gap: 20px;

    .desc-content {
      max-height: 100%;
    }

    .Col2 {
      font-size: 12px;

      button {
        font-size: 14px;
        padding: 10px;
      }
    }
  }
}

.BlockCenter {
  width: 100%;
  max-width: 1150px;
  padding-bottom: 50px;
  margin: 40px auto 0px auto;
}