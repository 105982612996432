@font-face {
    font-family: "Soleil";
    src: url("https://db.onlinewebfonts.com/t/4ff6a11cffa6e8559bd96d4fc1cfa275.eot");
    src: url("https://db.onlinewebfonts.com/t/4ff6a11cffa6e8559bd96d4fc1cfa275.eot?#iefix") format("embedded-opentype"),
        url("./fonts/4ff6a11cffa6e8559bd96d4fc1cfa275.woff2") format("woff2"),
        url("https://db.onlinewebfonts.com/t/4ff6a11cffa6e8559bd96d4fc1cfa275.woff") format("woff"),
        url("https://db.onlinewebfonts.com/t/4ff6a11cffa6e8559bd96d4fc1cfa275.ttf") format("truetype"),
        url("https://db.onlinewebfonts.com/t/4ff6a11cffa6e8559bd96d4fc1cfa275.svg#SoleilW01-Regular") format("svg");
}

@font-face {
    font-family: "Soleil";
    src: url("https://db.onlinewebfonts.com/t/a9d2e51be59e365796251ab6d63be17b.eot");
    src: url("https://db.onlinewebfonts.com/t/a9d2e51be59e365796251ab6d63be17b.eot?#iefix") format("embedded-opentype"),
        url("./fonts/a9d2e51be59e365796251ab6d63be17b.woff2") format("woff2"),
        url("https://db.onlinewebfonts.com/t/a9d2e51be59e365796251ab6d63be17b.woff") format("woff"),
        url("https://db.onlinewebfonts.com/t/a9d2e51be59e365796251ab6d63be17b.ttf") format("truetype"),
        url("https://db.onlinewebfonts.com/t/a9d2e51be59e365796251ab6d63be17b.svg#Soleil") format("svg");
    font-weight: bold;
}

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

body {
    font-family: Soleil;
}

div,
input,
button,
textarea {
    font-family: Soleil;
}

button {
    outline: none;
    cursor: pointer;
}

input,
textarea {
    outline: none;
    -webkit-appearance: none;
}

input::placeholder,
textarea::placeholder {
    color: #919191;
}

input:focus,
textarea:focus {
    border-color: #4D90FE;
}
