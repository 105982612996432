.document-edit-view {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;

    .toolbar {
        display: flex;
        flex: 0 0 48px;
        padding: 0px 10px;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 1px 0px #DBDBDB;

        .back-button,
        .save-button {
            position: relative;
            top: 0px;
            right: 0px;
            z-index: 2;
            height: 32px;
            font-size: 12px;
            padding: 8px 15px;
            line-height: 15px;
            font-weight: bold;
            border-radius: 4px;
            text-transform: capitalize;
        }

        .save-button {
            color: white;
            margin: 0px 0px 0px 10px;
        }

        .bg-disabled {
            cursor: not-allowed;
            background: #919191 !important;
        }

        .page-title {
            position: absolute;
            left: 0px;
            right: 0px;
            text-align: center;
            text-transform: capitalize;
        }

        .button-frame {
            display: flex;
        }

        .mobile-button {
            position: relative;
            z-index: 2;
            width: 32px;
            height: 32px;
            display: flex;
            cursor: pointer;
            border-radius: 4px;
            align-items: center;
            justify-content: center;

            .mobile-button-icon {
                width: 14px;
                height: 14px;
                margin-top: -7px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .mobile-button.delete {
            margin-right: 8px;

            .mobile-button-icon {
                width: 20px;
                height: 20px;
            }
        }

        .mobile-button.edit {
            .mobile-button-icon {
                width: 20px;
                height: 20px;
            }
        }

        .mobile-button.save {
            .mobile-button-icon {
                width: 18px;
                height: 16px;

                img {
                    width: 17.59px;
                    height: 13.41px;
                }
            }
        }
    }

    .content-frame {
        flex: 1 0;

        .document-frame {
            display: flex;
            width: 90%;
            flex-wrap: wrap;
            max-width: 960px;
            margin: 30px auto 0px auto;
            justify-content: space-between;

            .flex-frame {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .key-value-frame {
                position: relative;
                width: 30%;
                margin-bottom: 42px;

                .disabled {
                    position: absolute;
                    top: 23px;
                    left: 0px;
                    right: 0px;
                    z-index: 2;
                    height: 34px;
                    opacity: 0.5;
                    cursor: not-allowed;
                    border-radius: 4px;
                    background: lightgray;
                }

                .value-label {
                    font-size: 12px;
                    line-height: 160%;
                    margin-bottom: 4px;
                }

                .key-value-menu {
                    margin: 0px;
                    width: 100%;
                    height: 34px;
                    appearance: none;
                    background: none;
                    border-radius: 4px;
                    border: 1px solid #DBDBDB;

                    select {
                        width: 100%;
                        height: 100%;
                        font-size: 12px;
                        font-weight: 600;
                        box-sizing: border-box;
                        padding: 6px 24px 6px 10px;
                    }
                }

                .key-value-menu.bg-placeholder,
                .key-value-textarea:placeholder-shown {
                    background: #FAFAFA;

                    select {
                        color: #919191;
                    }
                }

                .key-value-input,
                .key-value-textarea {
                    margin: 0px;
                    width: 100%;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 7px 10px;
                    border-radius: 4px;
                    box-sizing: border-box;
                    border: 1px solid #DBDBDB;
                }

                .key-value-textarea {
                    resize: none;
                    height: 100px;
                    margin-bottom: 0px;
                }

                .key-value-file-input {
                    position: relative;
                    width: 100%;
                    height: 32px;
                    display: flex;
                    padding: 4px;
                    border-radius: 4px;
                    align-items: center;
                    box-sizing: border-box;
                    border: 1px solid #DBDBDB;
                    justify-content: space-between;

                    .file-input {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        bottom: 0px;
                        width: 80px;
                        opacity: 0;
                    }

                    .file-label {
                        flex: 1 0;
                        margin: 0px 6px;
                        font-size: 12px;
                        line-height: 15px;
                    }

                    .file-button {
                        height: 24px;
                        border: none;
                        flex: 0 0 80px;
                        font-size: 10px;
                        font-weight: bold;
                        line-height: 130%;
                        border-radius: 2px;
                    }

                    .file-button.bg-grey-2 {
                        color: #2A2A2A;
                    }
                }

                .key-value-file-input.bg-placeholder {
                    .file-label {
                        color: #919191;
                    }
                }

                .checkbox-frame {
                    display: flex;
                    margin-top: 12px;
                    align-items: center;

                    .checkbox-label {
                        font-size: 12px;
                        line-height: 15px;
                        margin-left: 10px;
                    }
                }
            }

            .key-value-frame.full {
                width: 100%;

                .flex-frame {
                    justify-content: flex-start;

                    .hint {
                        margin-left: 3px;
                    }
                }

                .signature-frame {
                    .signature-input-frame {
                        height: 200px;
                        display: flex;
                        overflow: hidden;
                        border-radius: 4px;
                        align-items: flex-end;
                        justify-content: center;
                        border: 1px solid #DBDBDB;

                        .signature-canvas {
                            position: absolute;
                        }

                        .signature-border {
                            width: 75%;
                            height: 1px;
                            margin-bottom: 50px;
                            background: black;
                        }
                    }

                    .clear-signature-button {
                        float: right;
                        background: none;
                        margin-top: 10px;
                        text-transform: capitalize;
                    }
                }

                .form-frame {
                    .form-name {
                        font-size: 14px;
                        font-weight: bold;
                        text-align: center;
                        margin-bottom: 15px;
                    }

                    .form-label {
                        font-size: 12px;
                    }

                    .form-label.underline {
                        margin-right: 4px;
                        text-decoration: underline;
                    }

                    .form-label.line-break {
                        margin-bottom: 16px;
                    }

                    .form-list-view {
                        .form-label {
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }

        .document-frame.mobile {
            width: 88%;
            margin: 12px auto;

            .key-value-frame {
                width: 100%;
                margin-bottom: 12px;

                .value-label {
                    font-size: 13px;
                    line-height: 130%;
                }
            }

            .key-value-frame.empty {
                margin: 0px;
            }
        }
    }
}