.roster-packet-view {
    .toolbar {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 48px;
        display: flex;
        padding: 0px 8px;
        align-items: center;
        background-color: #FFFFFF;
        justify-content: space-between;
        box-shadow: 0px 1px 0px #DBDBDB;

        .cancel-button {
            position: relative;
            top: auto;
            left: auto;
            width: 70px;
            z-index: 1;
            border: none;
            font-size: 12px;
            color: #2A2A2A;
            line-height: 100%;
            padding: 9px 10px;
            border-radius: 4px;
            background-color: #F2F2F2;
        }

        .header-frame {
            position: absolute;
            left: 0px;
            right: 0px;
            z-index: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .header-label {
                font-size: 16px;
            }

            .avatar {
                width: 36px;
                height: 36px;
                margin: 0px 5px;
                overflow: hidden;
                border-radius: 50%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .button-frame {
            position: relative;
            z-index: 2;
            height: 32px;
            display: flex;
            min-width: 125px;
            align-items: center;

            .switch-frame {
                position: relative;
                top: auto;
                right: auto;
                align-items: center;
            }

            .create-invite-button {
                z-index: 1;
                margin: 0px;
                height: 32px;
                border: none;
                color: white;
                margin-left: 30px;
                font-size: 12px;
                line-height: 100%;
                padding: 9px 10px;
                border-radius: 4px;
                background-color: #F2F2F2;
            }

            .print-packet-button {
                display: flex;
                cursor: pointer;
                padding: 0px 10px;
                border-radius: 4px;
                align-items: center;
                background: #F2F2F2;

                .print-icon {
                    width: 13.33px;
                    height: 21px;
                    margin-right: 11px;

                    img {
                        width: 13.33px;
                        height: 12px;
                    }
                }

                .print-label {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 100%;
                }
            }
        }
    }

    .content-frame {
        flex-grow: 1;

        .centered-frame {
            margin: 48px auto;

            .section-frame {
                margin-bottom: 48px;

                .section-header-frame {
                    display: flex;
                    padding: 5px 10px;
                    align-items: center;
                    background: #F2F2F2;
                    justify-content: space-between;

                    .header {
                        font-size: 24px;
                        font-weight: bold;
                        text-transform: capitalize;
                    }

                    .flex-frame {
                        display: flex;

                        .select-all-button, .clear-button {
                            border: none;
                            font-size: 12px;
                            background: none;
                            font-weight: bold;
                        }

                        .clear-button {
                            margin-right: 30px;
                        }
                    }
                }

                .section-list-view {
                    column-count: 1;
                    list-style: none;
                    padding: 10px 0px;
                    -webkit-column-count: 1;
                    margin: 0px;

                    .section-cell {
                        position: relative;
                        display: flex;
                        cursor: pointer;
                        padding: 12px 15px;
                        align-items: center;
                        break-inside: avoid-column;
                        -webkit-column-break-inside: avoid;

                        .checkbox {
                            flex-grow: 0;
                            flex-shrink: 0;
                            flex-basis: 16px;
                        }

                        .cell-label {
                            font-size: 14px;
                            line-height: 130%;
                        }

                        .flex-frame {
                            display: flex;
                            align-items: center;

                            .cell-label {
                                // width: 100%;
                                font-size: 14px;
                            }

                            .green, .orange {
                                margin-left: 5px;
                            }
                        }

                        .flex-frame.column {
                            margin-left: 10px;
                            align-items: flex-start;
                            flex-direction: column;
                        }

                        .title {
                            flex: 1 1;
                        }

                        .margin {
                            margin-right: 5px;
                        }
                    }

                    .section-cell:nth-child(even) {
                        background: #F6F8F8;
                    }
                }
            }
        }
    }
}