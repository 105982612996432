.roster-invite-view {
  .toolbar {
    display: flex;
    padding: 0px 10px;
    align-items: center;
    justify-content: space-between;

    .cancel-button,
    .save-button,
    .page-header {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      outline: none;
    }

    .header-frame {
      display: flex;
      align-items: center;

      .header-label {
        font-size: 16px;
      }

      .avatar {
        width: 36px;
        height: 36px;
        margin: 0px 5px;
        overflow: hidden;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .content-frame {
    flex-grow: 1;

    .centered-frame {
      margin: 48px auto;

      .section-frame {
        overflow: hidden;
        transition: 500ms;
        margin-top: 48px;

        .section-header-frame {
          height: 53px;
          display: flex;
          padding: 0px 20px;
          align-items: center;
          background: #f2f2f2;
          justify-content: space-between;

          .section-header {
            font-size: 24px;
            font-weight: 600;
            line-height: 100%;
          }

          .flex-frame {
            .select-all-button,
            .resize-button {
              margin: 0px;
              border: none;
              outline: none;
              font-size: 12px;
              background: none;
              padding: 7.5px 0px;
            }

            .select-all-button {
              font-weight: bold;
              margin-right: 30px;
            }
          }
        }

        .section-list-view {
          column-count: 3;
          padding: 10px;
          list-style: none;
          -webkit-column-count: 3;
          margin: 7.5px 0px 0px 0px;

          .section-cell {
            position: relative;
            display: flex;
            cursor: pointer;
            padding: 7.5px 0px;
            align-items: center;
            break-inside: avoid-column;
            -webkit-column-break-inside: avoid;

            .checkbox {
              flex-grow: 0;
              flex-shrink: 0;
              flex-basis: 16px;
            }

            .section-label {
              top: -2px;
              font-size: 14px;
              margin-left: 10px;
              line-height: 15px;
            }

            .section-label.red {
              font-size: 9px;
              margin-left: 3px;
            }
          }
        }
      }
    }

    .selected {
      margin-left: 40px;
      display: flex;
      margin-top: 7.5px;
    }
    .selected-list {
      font-size: 14px;
      padding-inline-start: 10px;
      margin-block-start: 0px;
      line-height: 18px;
      line-break: anywhere;
      display: inline;

      .selected-item {
        display: inline;
        margin-right: 10px;
        white-space: nowrap;
      }
      .selected-item:before {
        content: '\2022';
        margin-right: 0.15em;
        font-size: 20px;
      }
    }
  }
}
