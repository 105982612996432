.job-post-title {
    font-size: 1.25rem;
    font-weight: bold;
    padding-bottom: 15px;
    color: #FF5A5F;
}

.job-post-title~div {
    color: rgb(0, 180, 180);
    font-size: 1rem;
    margin-top: 0.3rem;
    margin-left: 0.35rem;
}

.job-post-heading-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: solid 2px #DDD;
}

.job-post-title-container {
    display: flex;
    flex-wrap: wrap;
}

.job-id-value {
    font-weight: 600;
    float: right;
    margin-top: -0.35rem;
}

.label-heading {
    font-weight: 600;
}

.job-labels-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.25rem;
    font-size: 0.8rem;
}

.label-cell {
    border-left: solid 1px #DDD;
    padding: 0;
    padding-left: 2rem;
    padding-right: 2rem;
}

.label-cell:nth-child(even) {
    margin-top: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
}

.label-cell-left {
    max-width: 14rem;
    padding: 1rem;
    padding-top: 0;
    padding-left: 0;
}

.label-cell-right {
    border-left: solid 1px #DDD;
    max-width: 21rem;
    padding: 0;
    padding-left: 2rem;
}

.data-table-heading-container {
    background-color: #F2F2F2;
    border-top: solid 1px #CCC;
    border-bottom: solid 1px #CCC;
    width: 100vw;
    height: 2rem;
    margin-left: calc((1200px - 100vw)/2);
}

.data-table-container {
    margin-top: 2rem;
}

.data-table {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -2.75rem;

    thead {
        font-weight: 600;
        border: 0;
    }

    tr>td {
        padding-top: 1rem;
    }

    .data-row>td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7) {
        text-align: center;
        width: 10rem;
    }

    .action-button {
        cursor: pointer;
        text-decoration: none;
    }
}

.text-small {
    font-size: 0.75rem;
}

.cursor-pointer {
    cursor: pointer;
}

// using the below class cuz a foreign style is affecting the td of the data table
.data-table tbody tr td {
    width: auto !important;
}

.jobpost-text-danger {
    color: rgb(255, 90, 95);
}

.text-bold {
    font-weight: 700;
}

.no-applicants {
    text-align: center;
    color: #666;
    margin: 1rem;
}

.note-container>span {
    font-weight: 700;
    color: rgb(255, 90, 95);
}

.hover-grey:hover {
    background-color: #CCC;
    cursor: pointer;
    border-radius: 2px;
}