.black {
    color: #2A2A2A;
}

.blue {
    color: #24A4DB;
}

.hint {
    color: #919191 !important;
}

.green {
    color: #3EC253;
}

.red, .una {
    color: #FF5A5F !important;
}

.teal {
    color: #008489 !important;
}

.orange {
    color: #EB903D;
}

.navy {
    color: #094879 !important;
}




.bg-blue {
    background-color: #24A4DB;
}

.bg-green {
    background-color: #3EC253;
}

.bg-orange {
    background-color: #EB903D;   
}

.bg-red, .bg-una {
    background-color: #FF5A5F !important;
}

.bg-teal {
    background-color: #008489 !important;
}