.dashboard-clients-view {
    display: flex;
    flex-direction: column;

    .stats-frame {
        width: 100%;
        flex: 0 0 86px;
        flex-basis: 86px;
        padding: 30px 0px;
        border-bottom: 1px solid #DBDBDB;

        .centered-frame {
            display: flex;
            justify-content: space-between;

            .stat-frame {
                width: 30%;

                .header {
                    font-size: 14px;
                    color: #919191;
                    line-height: 18px;
                    text-align: center;
                }

                .stat-label {
                    margin-top: 7px;
                    font-size: 48px;
                    color: #919191;
                    line-height: 61px;
                    text-align: center;
                }

                .stat-label.black {
                    color: #484848;
                }
            }

            .stat-frame.middle {
                border-left: 1px solid #DBDBDB;
                border-right: 1px solid #DBDBDB;
            }
        }
    }

    .secondary-toolbar-frame {
        flex: 0 0 49px;
        border-bottom: 1px solid #DBDBDB;

        .centered-frame {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .create-button {
                margin: 0px;
                border: none;
                padding: 5px;
                font-size: 12px;
                background: none;
            }
        }
    }

    .dashboard-table-view {
        position: relative;
        display: flex;
        flex: 1 1;
        flex-direction: column;

        .table-headers {
            .title {
                width: 28%;
            }

            .type {
                width: 20%;
            }

            .content {
                width: 12%;
            }

            .plan {
                width: 14%;
            }
                
            .frequency {
                width: 18%;
            }

            .status {
                width: 10%;
            }
        }

        .dashboard-list-view {
            position: absolute;
            top: 33px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            margin: 0px;
            padding: 0px;
            flex: 1 1;
            list-style: none;

            .dashboard-cell {
                position: relative;
                height: 48px;
                cursor: pointer;

                .org-logo {
                    position: absolute;
                    top: 25%;
                    left: 10px;
                    height: 50%;
                }

                .centered-frame {
                    position: relative;
                    height: 100%;
                    display: flex;

                    .cell-content-frame {
                        flex-grow: 1;
                        display: flex;
                        align-items: center;
                    }

                    .cell-label {
                        font-size: 12px;
                    }

                    .title {
                        width: 28%;
                    }

                    .type {
                        width: 20%;
                    }

                    .content {
                        width: 12%;
                    }
        
                    .plan {
                        width: 14%;
                    }
                        
                    .frequency {
                        width: 18%;
                    }
        
                    .status {
                        width: 8%;
                    }
                }

                .expander-icon {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    width: 22px;
                    height: 100%;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 11px;
                        height: 3px;
                    }
                }
            }

            .dashboard-cell:nth-child(even) {
                background: #FAFAFA;
            }
        }

        .empty-label {
            font-size: 14px;
            color: #919191;
            font-style: italic;
        }
    }
}

.clients.menu-pane {
    .menu-content-view {
        top: 65px !important;
        margin-top: 0px !important;
    }
}