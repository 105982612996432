.plus-btn {
  width: 2%;
  font-weight: bold;
  color: '#FF5A5F';
}

.submit-btn {
  height: 35px;
  width: 12%;
  color: white;
  background: rgb(33, 163, 33);
}

.input-field {
  position: relative;
  text-transform: capitalize;
  text-overflow: ellipsis;
  font-size: 12px;
  border: none;
  height: 100%;
  background: transparent;
}

.outer-td {
  width: '10%';
  height: '0%';
}

.dashboard-feedback-view {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  -webkit-touch-callout: none; // iOS Safari
  -webkit-user-select: none; // Safari
  -khtml-user-select: none; // Konqueror HTML
  -moz-user-select: none; //Old versions of Firefox
  -ms-user-select: none; //Internet Explorer/Edge
  user-select: none; //Non-prefixed version, currently supported by Chrome, Opera and Firefox

  .stats-frame {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 86px;
    padding: 30px 0px;
    border-bottom: 1px solid #dbdbdb;

    .centered-frame {
      display: flex;
      justify-content: space-between;

      .stat-frame {
        width: 30%;

        .header {
          font-size: 14px;
          color: #919191;
          line-height: 18px;
          text-align: center;
        }

        .stat-label {
          margin-top: 7px;
          font-size: 48px;
          color: #919191;
          line-height: 61px;
          text-align: center;
        }

        .stat-label.black {
          color: #484848;
        }

        .stat-label.teal {
          color: #008489;
        }
      }

      .stat-frame.middle {
        border-left: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
      }
    }
  }

  .filter-frame {
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #e8e8e8;

    .centered-frame {
      position: relative;

      .search {
        float: left;
        margin-top: 7.4px;
        margin-left: -1.5px;
        font-size: 12px;
        font-weight: 600;
        color: #484848;
        line-height: 15px;
        background: #ffffff;
        border: 1px solid #dbdbdb;
        box-sizing: border-box;
        border-radius: 4px;
        width: 20%;
        height: 32px;
        padding: 7px 10px;
        resize: none;
        white-space: pre-line;
      }

      .magnifying-glass {
        position: absolute;
        top: 14px;
        left: 6px;
        width: 16px;
        height: 16px;
      }

      .create-test-button {
        float: right;
        border: none;
        padding: 14.5px 0px 0 0;
        font-size: 12px;
        background: none;
        line-height: 15px;
      }
    }
  }

  .secondary-toolbar-frame {
    height: 48px;
    box-shadow: 0px 1px 0px #dbdbdb;

    .centered-frame {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .create-test-button {
        margin: 0px;
        border: none;
        padding: 0px;
        font-style: 12px;
        background: none;
      }
    }
  }

  .empty-label {
    opacity: 0.5;
    font-size: 21px;
    font-style: italic;
  }

  .FeedbackFormerboss {
    height: 500px;
  }

  .feedback-table-view {
    position: absolute;
    top: 48px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 500px;

    .table-headers {
      height: 30px;
      background: #f2f2f2;
      border-top: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;

      .centered-frame {
        .table-header {
          position: relative;
          top: 7.5px;
          margin-right: 4px;
          float: left;
          font-size: 12px;
          overflow: hidden;
          line-height: 15px;
          text-overflow: ellipsis;
        }

        .title {
          width: 24%;
        }

        .title.small {
          width: 19%;
        }

        .title.long {
          width: 35%;
        }

        .duration {
          width: 9%;
        }

        .passing {
          width: 11%;
        }

        .categories {
          width: 11.5%;
        }

        .questions {
          width: 12.5%;
        }

        .date {
          width: 14%;
        }

        .status {
          width: 8%;
        }

        .list {
          width: 10%;
        }

        .list.long {
          width: 15%;
        }

        .list.extra {
          width: 22%;
        }
      }
    }

    .tests-list-view {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      margin: 0px;
      padding: 0px;
      list-style: none;

      .test-cell {
        height: 36px;
        cursor: pointer;

        .centered-frame {
          position: relative;
          height: 36px;

          .cell-content-frame {
            position: relative;
            height: 100%;

            .cell-label {
              position: relative;
              top: 9.5px;
              width: 7%;
              float: left;
              font-size: 12px;
              margin-right: 4px;
              text-transform: capitalize;
              text-overflow: ellipsis;
            }

            .title {
              width: 23%;
              font-weight: 900;
            }

            .title.small {
              width: 18%;
            }

            .title.long {
              width: 34%;
            }

            .duration {
              width: 8%;
            }

            .passing {
              width: 10%;
            }

            .categories {
              width: 10.5%;
            }

            .questions {
              width: 12.5%;
            }

            .date {
              width: 13%;
            }

            .status {
              width: 7%;
            }

            .list {
              width: 10%;
            }

            .list.long {
              width: 15%;
            }

            .list.extra {
              width: 22%;
            }
          }
        }

        .expander-icon {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 5%;
          height: 100%;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;

          img {
            width: 11px;
            height: 3px;
          }
        }
      }

      .test-cell.disabled {
        cursor: not-allowed !important;
        background: #dddddd !important;
        border-bottom: 1px solid #e8e8e8;

        .cell-label {
          color: #919191 !important;
        }
      }

      .test-cell:nth-child(even) {
        background: #fafafa;
      }
    }
  }

  .feedback-table-viewtwo {
    position: absolute;
    top: 300px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 220px;

    .table-headers {
      height: 30px;
      background: #f2f2f2;
      border-top: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;

      .centered-frame {
        .table-header {
          position: relative;
          top: 7.5px;
          float: left;
          width: 8%;
          font-size: 12px;
          margin-right: 4px;
          overflow: hidden;
          line-height: 15px;
          text-overflow: ellipsis;
          width: 100%;
        }

        .title {
          width: 24%;
        }

        .title.small {
          width: 19%;
        }

        .title.long {
          width: 35%;
        }

        .duration {
          width: 9%;
        }

        .passing {
          width: 11%;
        }

        .categories {
          width: 11.5%;
        }

        .questions {
          width: 12%;
        }

        .date {
          width: 14%;
        }

        .status {
          width: 8%;
        }

        .list {
          width: 12%;
        }

        .list.long {
          width: 18%;
        }
      }
    }

    .tests-list-view {
      position: absolute;
      top: 38px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      margin: 0px;
      padding: 0px;
      list-style: none;

      .test-cell {
        height: 36px;
        cursor: pointer;

        .centered-frame {
          position: relative;
          height: 36px;

          .cell-content-frame {
            position: relative;
            z-index: 0;
            height: 100%;

            .cell-label {
              position: relative;
              top: 9.5px;
              float: left;
              font-size: 12px;
              margin-right: 4px;
              text-transform: capitalize;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .title {
              width: 23%;
              font-weight: 900;
            }

            .title.small {
              width: 18%;
            }

            .title.long {
              width: 34%;
            }

            .duration {
              width: 8%;
            }

            .passing {
              width: 10%;
            }

            .categories {
              width: 10.5%;
            }

            .questions {
              width: 11%;
            }

            .date {
              width: 13%;
            }

            .status {
              width: 7%;
            }

            .list {
              width: 12%;
            }

            .list.long {
              width: 18%;
            }
          }
        }

        .expander-icon {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 5%;
          height: 100%;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;

          img {
            width: 11px;
            height: 3px;
          }
        }
      }

      .test-cell.disabled {
        cursor: not-allowed !important;
        background: #dddddd !important;
        border-bottom: 1px solid #e8e8e8;

        .cell-label {
          color: #919191 !important;
        }
      }

      .test-cell:nth-child(even) {
        background: #fafafa;
      }
    }
  }

  // .feedback-table-viewthree {
  //   position: absolute;
  //   top: 48px;
  //   left: 0px;
  //   right: 0px;
  //   bottom: 0px;
  //   height: 400px;

  //   .table-headers {
  //     height: 30px;
  //     background: #f2f2f2;
  //     border-top: 1px solid #e8e8e8;
  //     border-bottom: 1px solid #e8e8e8;

  //     .centered-frame {
  //       .table-header {
  //         position: relative;
  //         top: 7.5px;
  //         float: left;
  //         margin-right: 4px;
  //         font-size: 12px;
  //         overflow: hidden;
  //         line-height: 15px;
  //         text-overflow: ellipsis;
  //         width: 100%;
  //       }

  //       .title {
  //         width: 24%;
  //       }

  //       .title.small {
  //         width: 19%;
  //       }

  //       .title.long {
  //         width: 35%;
  //       }

  //       .duration {
  //         width: 9%;
  //       }

  //       .passing {
  //         width: 11%;
  //       }

  //       .categories {
  //         width: 11.5%;
  //       }

  //       .questions {
  //         width: 12%;
  //       }

  //       .date {
  //         width: 14%;
  //       }

  //       .status {
  //         width: 8%;
  //       }

  //       .list {
  //         width: 10%;
  //       }

  //       .long {
  //         width: 15%;
  //       }
  //       .extralong {
  //         width: 30%;
  //       }
  //     }
  //   }

  //   .tests-list-view {
  //     position: absolute;
  //     top: 38px;
  //     left: 0px;
  //     right: 0px;
  //     bottom: 0px;
  //     margin: 0px;
  //     padding: 0px;
  //     list-style: none;

  //     .test-cell {
  //       height: 36px;
  //       cursor: pointer;

  //       .centered-frame {
  //         position: relative;
  //         height: 36px;
  //         width: 100%;

  //         .cell-content-frame {
  //           position: relative;
  //           z-index: 0;
  //           height: 100%;

  //           .cell-label {
  //             position: relative;
  //             top: 8px;
  //             width: 100%;
  //             margin-right: 4px;
  //             float: left;
  //             font-size: 12px;
  //             text-transform: capitalize;
  //             overflow: hidden;
  //             text-overflow: ellipsis;
  //           }

  //           .title {
  //             width: 23%;
  //             font-weight: 900;
  //           }

  //           .title.small {
  //             width: 18%;
  //           }

  //           .title.long {
  //             width: 34%;
  //           }

  //           .duration {
  //             width: 8%;
  //           }

  //           .passing {
  //             width: 10%;
  //           }

  //           .categories {
  //             width: 10.5%;
  //           }

  //           .questions {
  //             width: 11%;
  //           }

  //           .date {
  //             width: 13%;
  //           }

  //           .status {
  //             width: 7%;
  //           }

  //           .list {
  //             width: 10%;
  //           }

  //           .long {
  //             width: 15%;
  //             margin-right: 1px;
  //           }
  //           .extralong {
  //             width: 30%;
  //           }
  //         }
  //       }

  //       .expander-icon {
  //         top: 0px;
  //         right: 0px;
  //         width: 5%;
  //         height: 100%;
  //         display: flex;
  //         cursor: pointer;
  //         align-items: center;
  //         justify-content: center;

  //         img {
  //           width: 11px;
  //           height: 3px;
  //         }
  //       }
  //     }

  //     .test-cell.disabled {
  //       cursor: not-allowed !important;
  //       background: #dddddd !important;
  //       border-bottom: 1px solid #e8e8e8;

  //       .cell-label {
  //         color: #919191 !important;
  //       }
  //     }

  //     .test-cell:nth-child(even) {
  //       background: #fafafa;
  //     }
  //   }
  // }

  .modaloverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .modal-pane {
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 20px;
      text-align: center;
      position: relative;
      z-index: 1001;
    }
  }


  .feedback-table-viewthree {
    position: absolute;
    top: 48px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 400px;

    .table-headers {
      height: 30px;
      background: #f2f2f2;
      border-top: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;

      .centered-frame {
        .table-header {
          position: relative;
          top: 7.5px;
          float: left;
          margin-right: 4px;
          font-size: 12px;
          overflow: hidden;
          line-height: 15px;
          text-overflow: ellipsis;
          width: 100%;
        }

        .title {
          width: 24%;
        }

        .title.small {
          width: 19%;
        }

        .title.long {
          width: 35%;
        }

        .duration {
          width: 9%;
        }

        .passing {
          width: 11%;
        }

        .categories {
          width: 11.5%;
        }

        .questions {
          width: 12%;
        }

        .date {
          width: 14%;
        }

        .status {
          width: 8%;
        }

        .list {
          width: 10%;
        }

        .long {
          width: 21%;
        }

        .extralong {
          width: 30%;
        }
      }
    }

    .tests-list-view {
      position: absolute;
      top: 38px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      margin: 0px;
      padding: 0px;
      list-style: none;

      .test-cell {
        height: 36px;
        cursor: pointer;

        .centered-frame {
          position: relative;
          height: 36px;
          width: 100%;

          .cell-content-frame {
            position: relative;
            z-index: 0;
            height: 100%;

            .cell-label {
              position: relative;
              top: 8px;
              width: 100%;
              margin-right: 4px;
              float: left;
              font-size: 12px;
              text-transform: capitalize;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .title {
              width: 23%;
              font-weight: 900;
            }

            .title.small {
              width: 18%;
            }

            .title.long {
              width: 34%;
            }

            .duration {
              width: 8%;
            }

            .passing {
              width: 10%;
            }

            .categories {
              width: 10.5%;
            }

            .questions {
              width: 11%;
            }

            .date {
              width: 13%;
            }

            .status {
              width: 7%;
            }

            .list {
              width: 10%;
            }

            .long {
              width: 21%;
              margin-right: 1px;
            }

            .extralong {
              width: 30%;
            }

            .checkbox {
              top: 3px;
              appearance: auto;
              -webkit-appearance: auto;
            }
          }
        }

        .expander-icon {
          top: 0px;
          right: 0px;
          width: 5%;
          height: 100%;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;

          img {
            width: 11px;
            height: 3px;
          }
        }
      }

      .test-cell.disabled {
        cursor: not-allowed !important;
        background: #dddddd !important;
        border-bottom: 1px solid #e8e8e8;

        .cell-label {
          color: #919191 !important;
        }
      }

      .test-cell:nth-child(even) {
        background: #fafafa;
      }
    }
  }
}

.dropdown-container {
  display: flex;

  .selectdropdown {
    width: 270px;
    font-size: 12px;
    margin-right: 10px;
  }
}

.css-t3ipsp-control {
  min-height: 28px !important;
}

.css-1xc3v61-indicatorContainer {
  padding: 0px !important;
}

.css-hlgwow {
  padding: 0px 8px !important;
}

.content-frame::-webkit-scrollbar {
  display: none;
}

.content-frame {
  flex: 1 1;
  overflow-y: scroll;

  .document-frame {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    max-width: 960px;
    margin: 30px auto 0px auto;
    justify-content: space-between;

    .flex-frame {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .key-value-frame {
      position: relative;
      width: 30%;
      margin-bottom: 42px;

      .disabled {
        position: absolute;
        top: 23px;
        left: 0px;
        right: 0px;
        z-index: 2;
        height: 34px;
        opacity: 0.5;
        cursor: not-allowed;
        border-radius: 4px;
        background: lightgray;
      }

      .value-label {
        font-size: 12px;
        line-height: 160%;
        margin-bottom: 4px;
      }

      .key-value-menu {
        margin: 0px;
        width: 100%;
        height: 34px;
        appearance: none;
        background: none;
        border-radius: 4px;
        border: 1px solid #dbdbdb;

        select {
          width: 100%;
          height: 100%;
          font-size: 12px;
          font-weight: 500;
          box-sizing: border-box;
          padding: 6px 24px 6px 10px;
        }
      }

      .key-value-menu.bg-placeholder,
      .key-value-textarea:placeholder-shown {
        // background: #FAFAFA;

        select {
          color: #919191;
        }
      }

      .key-value-input,
      .key-value-textarea {
        margin: 0px;
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        padding: 7px 10px;
        border-radius: 4px;
        box-sizing: border-box;
        border: 1px solid #dbdbdb;
      }

      .key-value-textarea {
        resize: none;
        height: 100px;
        margin-bottom: 0px;
      }

      .key-value-file-input {
        position: relative;
        width: 100%;
        height: 32px;
        display: flex;
        padding: 4px;
        border-radius: 4px;
        align-items: center;
        box-sizing: border-box;
        border: 1px solid #dbdbdb;
        justify-content: space-between;

        .file-input {
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          width: 80px;
          opacity: 0;
        }

        .file-label {
          flex: 1 0;
          margin: 0px 6px;
          font-size: 12px;
          line-height: 15px;
        }

        .file-button {
          height: 24px;
          border: none;
          color: white;
          flex: 0 0 80px;
          cursor: initial;
          font-size: 10px;
          font-weight: bold;
          line-height: 130%;
          border-radius: 2px;
        }

        .file-button.bg-grey-2 {
          color: #2a2a2a;
        }
      }

      .key-value-file-input.bg-placeholder {
        .file-label {
          color: #919191;
        }
      }

      .checkbox-frame {
        display: flex;
        margin-top: 12px;
        align-items: center;

        .checkbox-label {
          font-size: 12px;
          line-height: 15px;
          margin-left: 10px;
        }
      }
    }

    .key-value-frame.full {
      width: 100%;

      .flex-frame {
        justify-content: flex-start;

        .hint {
          margin-left: 3px;
        }
      }

      .signature-frame {
        .signature-input-frame {
          height: 200px;
          display: flex;
          overflow: hidden;
          border-radius: 4px;
          align-items: flex-end;
          justify-content: center;
          border: 1px solid #dbdbdb;

          .signature-canvas {
            position: absolute;
          }

          .signature-border {
            width: 75%;
            height: 1px;
            margin-bottom: 50px;
            background: black;
          }
        }

        .clear-signature-button {
          float: right;
          background: none;
          margin-top: 10px;
          text-transform: capitalize;
        }
      }

      .form-frame {
        .form-name {
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 15px;
        }

        .form-label {
          font-size: 12px;
        }

        .form-label.underline {
          margin-right: 4px;
          text-decoration: underline;
        }

        .form-label.line-break {
          margin-bottom: 16px;
        }

        // .form-list-view {
        //     .form-label {

        //     }
        // }

        .form-link {
          font-size: 12px;
        }
      }
    }
  }

  .document-frame.mobile {
    width: 88%;
    margin: 12px auto;

    .key-value-frame {
      width: 100%;
      margin-bottom: 12px;

      .value-label {
        font-size: 13px;
        line-height: 130%;
      }
    }

    .key-value-frame.empty {
      margin: 0px;
    }
  }
}

.css-13cymwt-control {
  max-height: 50px;
}

.css-1dyz3mf {
  height: 50px !important;
  overflow: auto;
}

.css-3w2yfm-ValueContainer {
  height: 50px !important;
  overflow-y: scroll !important;
}

.css-3w2yfm-ValueContainer::-webkit-scrollbar {
  width: 0px;
  height: 5px;
  border-radius: 30px;
}

.css-3w2yfm-ValueContainer::-webkit-scrollbar-thumb {
  border-radius: 30px;
}

.css-1dyz3mf {
  height: 50px !important;
  overflow-y: scroll !important;
}

.css-1dyz3mf::-webkit-scrollbar {
  width: 0px;
  height: 5px;
  border-radius: 30px;
}

.css-1dyz3mf::-webkit-scrollbar-thumb {
  border-radius: 30px;
}

.text-carousel {
  display: flex;
}

.arrow {
  background-color: transparent;
}

.text-carousel .text {
  margin: 0px 8px;
}